import React, { useEffect, useState } from 'react';
import Logo from '../common/logo';
import useWorkflows from '../../hooks/useWorkflows';
import { useNavigate } from 'react-router-dom';
import './NavigationPanel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/modalSlice';
import NewWorkflow from './NewWorkflow';
import DocumentUpload from './DocumentUpload';
import { CSSTransition } from 'react-transition-group';
import Settings from './Settings';
import Founders from './Founders';


const NavigationPanel = ({ paneExtended,
  togglePane,
  setCurrentWorkflowId,
  currentWorkflowId,
  setCurrentDocumentId,
  toggleNewWorkflowModal,
  workflows,
  expandedWorkflows,
  toggleWorkflow,
  refetchTrigger,
  triggerRefetch,
  setNewDocumentWorkflowId,
  setCurrentWorkflow }) => {

  let [selectedWorkflow, setSelectedWorkflow] = useState(currentWorkflowId); 

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleWorkflowClick = (workflowId, index) => {
    setSelectedWorkflow(workflowId);
    setCurrentWorkflowId(workflowId);
    setCurrentWorkflow(workflows[index]);
    setCurrentDocumentId(undefined);
    navigate(`/w/workflow/${workflowId}`);

    
  }


  // const handleNewWorkflowClick = () => {
  //   toggleNewWorkflowModal();
  // }

  const openNewWorkflowModal = () => {
    dispatch(openModal({
      component: NewWorkflow,
      props: {onUploadComplete: triggerRefetch}
    }));
  };

  const openNewDocumentModal = (workflowId) => {
    dispatch(openModal({
      component: DocumentUpload,
      props: {onUploadComplete: triggerRefetch, currentWorkflowId: workflowId}
    }));
  };

  const openSettingsModal = (workflowId) => {
    dispatch(openModal({
      component: Settings,
      props: {}
    }));
  };

  const openFoundersModal = (workflowId) => {

    dispatch(openModal({
      component: Founders,
      props: {}
    }));

  };

 

  const handleDocumentClick = (documentId) => {
    setCurrentDocumentId(documentId);
    setCurrentWorkflowId(undefined);
    // setCurrentWorkflow(undefined);
  }

  const handleNewDocument = (workflowId) => {
    openNewDocumentModal(workflowId);
  }

  




  return (
    <div className={`navigation-panel ${paneExtended ? 'extended' : 'collapsed'}`}>


      <div className="logo-and-toggle">
        <Logo />
        <div className="toggle" onClick={() => { togglePane(false) }}>
          <FontAwesomeIcon icon="fa-solid fa-bars" className='icon-btn' />
        </div>
      </div>


      <div className="workflow-and-new">
        <div className='nav-itm nav-itm-itm' onClick={() => openNewWorkflowModal()}>
          <FontAwesomeIcon icon='fa-solid fa-add' className='add-workflow' >
          </FontAwesomeIcon>New Workflow
        </div>

        <div className="workflows">
          <ul>
            {workflows
            .sort((a, b) => b.id - a.id)
            .map((workflow, index) => (
              <li key={workflow.id}>
                <div className={`nav-itm ${selectedWorkflow === workflow.id ? 'wf-selected' : ''}`}>

                  <div className="nv-icon">
                    <FontAwesomeIcon icon="fa-solid fa-bars-staggered"
                      className={`nav-itm-itm ${expandedWorkflows[workflow.id] ? 'rotated' : ''}`}
                      onClick={() => toggleWorkflow(workflow.id)}
                    />
                  </div>
                  <span 
                    className="nav-text nav-itm-itm" 
                    onClick={() => handleWorkflowClick(workflow.id, index)} >
                      {workflow.name}
                  </span>

                  {expandedWorkflows[workflow.id] &&
                    <FontAwesomeIcon icon='fa-solid fa-add' className='add-doc' onClick={() => handleNewDocument(workflow.id)}></FontAwesomeIcon>
                  }

                </div>
                <div>
                  <CSSTransition
                    in={expandedWorkflows[workflow.id]}
                    timeout={200}
                    classNames="list"
                    unmountOnExit
                  >
                    <ul className="document-list">
                      {workflow.documents.map((document) => (
                        <li
                          key={document.document_id}
                          onClick={() => handleDocumentClick(document.document_id)}
                        >
                          <div className="nav-itm inner-itm nav-itm-itm">
                            <span className="nav-text">{document.title}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </CSSTransition>
                </div>
              </li>
            ))}
          </ul>
        </div>

      </div>

      <div className="navigation-items">
        <ul>
          <li>
            <div className="nav-itm" onClick={() => { navigate('/w/flashcards') }}>
              <FontAwesomeIcon icon="fa-solid fa-clone" />
              <span className="nav-text">Flashcards</span></div>
          </li>
          <li>
            <div className="nav-itm" onClick={() => { navigate('/w/quizzes') }}>
              <FontAwesomeIcon icon="fa-solid fa-circle-question" />
              <span className="nav-text">Quizzes</span>
            </div>
          </li>
          <li>
            <div className="nav-itm">
              <FontAwesomeIcon icon="fa-solid fa-arrows-turn-to-dots" />
              <span className="nav-text">Mindmaps</span> <span className="soon">Soon!</span></div>
          </li>
        </ul>
      </div>
      <div className="settings">
        <ul>
          <li>
            <div className="nav-itm" onClick={openSettingsModal}>
              <FontAwesomeIcon icon='fa-solid fa-gear'></FontAwesomeIcon>
              <span className="nav-text">Settings</span>
            </div>
          </li>
          {/* <li>
            <div className="nav-itm">
              <FontAwesomeIcon icon='fa-solid fa-palette'></FontAwesomeIcon>
              <span className="nav-text">Theme</span>
            </div>
          </li> */}
          <li>
            <div className="nav-itm" onClick={openFoundersModal}>
              <FontAwesomeIcon icon='fa-solid fa-phone'></FontAwesomeIcon>
              <span className="nav-text">Talk to a founder</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavigationPanel;
