import React, { useState, useEffect, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Notes.css';
import api from '../../services/api';
import debounce from 'lodash/debounce';

const NotesSection = ({ className, objectId, objectType }) => {
  const [content, setContent] = useState('');
  const [noteId, setNoteId] = useState(null);

  useEffect(() => {
    // Fetch existing note content when component mounts
    const fetchNote = async () => {
      try {
        const response = await api.get(`/notes/${objectType}/${objectId}`);
        setContent(response.data.content);
       
      } catch (error) {
        console.error('Error fetching note:', error);
      }
    };
    fetchNote();
  }, [objectId, objectType]);

  const saveNote = useCallback(
    debounce(async (value) => {
      try {
        const response = await api.post('/notes', {
          content: value,
          object_type: objectType,
          object_id: objectId,
          id: noteId, // Include the note ID if it exists
        });
        setNoteId(response.data.id);
      } catch (error) {
        console.error('Error saving note:', error);
      }
    }, 1000),
    [objectId, objectType, noteId]
  );

  const handleChange = (value) => {
    setContent(value);
    saveNote(value);
  };

  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];


  return (
    <div className={`${className}`}>
      <ReactQuill
        className="custom-quill"
        value={content}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder="Write your notes here..."
      />
    </div>
  );
};

export default NotesSection;