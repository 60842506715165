import React, { useState, useRef } from 'react';
import './Initiation.css'; // This is the same CSS file we used before, now adapted for your requirements
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';

const Initiation = ({ onUploadComplete, name }) => {
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const [innerWorkflowId, setWorkflowId] = useState(null);
    const navigate = useNavigate();

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files).map(file => ({
            file,
            name: file.name,
            size: (file.size / (1024 * 1024)).toFixed(2), // size in MB
            progress: 0
        }));
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        event.target.value = null;
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files).map(file => ({
            file,
            name: file.name,
            size: (file.size / (1024 * 1024)).toFixed(2), // size in MB
            progress: 0
        }));
        setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const uploadFiles = async () => {
        let params = new URLSearchParams();
        params.append('workflow_title', 'First Workflow');
        const response = await api.post('/create-workflow', {}, {
            params: params,
        });
        console.log('response', response.data);
        let workflowId = response.data.workflow_id;
        console.log(workflowId)
        // setWorkflowId(response.data.workflow_id);
        setLoading(true);
        for (let i = 0; i < files.length; i++) {
            
            const formData = new FormData();
            formData.append('file', files[i].file);
           
            
            let params2 = new URLSearchParams();
            params2.append('workflow_id', workflowId);

          

            try {
                const response = await api.post('/upload', formData, {
                    params: params2,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true,
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress((prevProgress) => ({
                            ...prevProgress,
                            [i]: progress
                        }));
                    }
                });
                console.log('File uploaded:', response);
            } catch (error) {
                console.error('Error uploading file', error);
            }
        }
        setLoading(false);
        setFiles([]); // Clear the files after upload
        onUploadComplete();
        navigate('/w/workflow/' + workflowId);
    };

    const handleRemoveFile = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
        setUploadProgress((prevProgress) => {
            const newProgress = { ...prevProgress };
            delete newProgress[index];
            return newProgress;
        });
    };

    return (
        <div className="welcome-page">
            <h1>Welcome to <strong>deductiv</strong>, {name}</h1>
            <p>Import your documents and let deductiv do its magic.</p>

            <div className="init-file">
                <div className="action-container">
                    <div className="action-box import-box"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={() => { fileInputRef.current.click() }}>
                        <FontAwesomeIcon icon='fa-solid fa-upload' />
                        <h2>Import</h2>
                        <p>Add files into your library</p>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            className="file-input"
                            ref={fileInputRef}
                            multiple
                            hidden
                        />
                    </div>
                </div>

                <div className="init-staging-area">
                    {files.map((file, index) => (
                        <div key={index} className="staged-file">
                            <div className="file-icon">
                                <FontAwesomeIcon icon='fa-solid fa-file' />
                            </div>
                            <div className="file-info">
                                <span className='filename'>{file.name}</span>
                                <span>{file.size} MB</span>
                                <progress value={uploadProgress[index] || 0} max="100" />
                                <div

                                    className="remove-btn"
                                    onClick={() => handleRemoveFile(index)}
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-times" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* Upload button */}

                    <div className={`init-upload-btn ${files.length === 0 || loading ? 'disabled': ''}`}  onClick={uploadFiles}>{ loading && <div className='loader'></div>} Upload</div>

            </div>
        </div>
    );
};

export default Initiation;
