import React, { useState, useEffect } from "react";
import "./Flashcards.css";
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/modalSlice';
import NewSet from "./NewSet";
import api from "../../services/api";
import TopBar from './TopBar';



const Flashcards = ({navPaneExtended, toggleNavPane, checkAuth}) => {
    const [documents, setDocuments] = useState([]);
    const [flashcardSet, setFlashcardSet] = useState([]);
    const [flashcards, setFlashcards] = useState([]);
    const [selectedSet, setSelectedSet] = useState(null);
    const [flippedCards, setFlippedCards] = useState({});
    const [animate, setAnimate] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const getFlashcards = async () => {
        let response = await api.get("/flashcard-sets");
      
        setFlashcardSet(response.data);
    }


    useEffect(() => {
        api.get("/user-documents").then((response) => {
            console.log('documents')
            console.log(response);
            setDocuments(response.data);
        });
        getFlashcards();

    }, []);

    const openNewWorkflowModal = () => {
        dispatch(openModal({
            component: NewSet,
            props: { existingDocuments: documents,
                 createSetapi: '/create-flashcards',
                 title: 'Flashcard',
                 callBack: getFlashcards }
        }));
    };

    

    const handleSetClick = async (setId) => {
        // Dummy data for flashcards in the selected set
        api.get(`/flashcard-set/${setId}`).then((response) => {
            console.log('flashcards')
            console.log(response);
            setFlashcards(response.data);
        });

        setSelectedSet(setId);
        setFlippedCards({}); // Reset flipped cards when a new set is selected
        setAnimate(true); // Trigger the animation
    };

    const handleCardFlip = (cardId) => {
        setFlippedCards((prev) => ({
            ...prev,
            [cardId]: !prev[cardId],
        }));
    };

    const handleBackToSets = () => {
        setAnimate(false); // Reverse the animation
        setSelectedSet(null);
        setFlashcards([]);

    };

    return (
        <div className="flc-container">
            <TopBar navPaneExtended={navPaneExtended} toggleNavPane={toggleNavPane} checkAuth={checkAuth} title="Flashcards" newBtnClick={openNewWorkflowModal} />
            <div className="flc-internal">
             
                <div
                    className={`flc-content ${animate ? "animate" : ""} ${selectedSet ? "set-selected" : ""
                        }`}
                >
                    <div className="flc-sets">
                        {selectedSet && (
                            <div onClick={handleBackToSets} className="simple-btn">
                                &larr; Back to Sets
                            </div>
                        )}
                        <div className={`flc-sets-grid ${selectedSet ? "single" : ""}`}>
                            {flashcardSet.map((fs) => (
                                <div
                                    key={fs.flashcard_set_id}
                                    className={`flc-flashcard-item ${fs.flashcard_set_id === selectedSet ? "active" : ""
                                        }`}
                                    onClick={() => handleSetClick(fs.flashcard_set_id)}
                                >
                                    <h3>{fs.title}</h3>
                                    <img src="/bookset.jpg" alt="Set" />
                                </div>
                            ))}
                        </div>
                    </div>
                    {selectedSet && (
                        <div className="flc-flashcards">
                            {flashcards.map((flashcard) => (
                                <div
                                    key={flashcard.flashcard_id}
                                    className={`flashcard ${flippedCards[flashcard.flashcard_id] ? "flipped" : ""
                                        }`}
                                    onClick={() => handleCardFlip(flashcard.flashcard_id)}
                                >
                                    <div className="flashcard-inner">
                                        <div className="flashcard-front">
                                            {flashcard.front_text}
                                        </div>
                                        <div className="flashcard-back">
                                            {flashcard.back_text}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Flashcards;
