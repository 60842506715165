import { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../services/api';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkAuth = async () => {
    console.log('checking authentication status');
    try {
      const response = await api.get('/check', );

     
      setIsAuthenticated(true);
    } catch (error) {
      setIsAuthenticated(false);
      console.error("Failed to check authentication status", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return { isAuthenticated, loading, checkAuth };
};

export default useAuth;