import {react, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {openModal} from '../../store/modalSlice';
import './Founders.css'


const Founders = () => {
    
    return (
        <div className='founders'>
            <h1>Founders</h1>
            
            <div className='founder'>
                    <div className='founder-name'>Aasim Ahmed</div>
                    <div className="icons">
                        <img className='icon' src="/aasim.jpg" alt="Aasim" />
                        <img className='icon' src='/instagram.png' alt='instagram' />
                        
                        <img className='icon' src='/whatsapp.png' alt='whatsapp' />
                        <img className='icon' src='/gmail.png' alt='Founder 3' />
                    </div>
                   
          
            </div>

            <div className='founder'>
                    <div className='founder-name'>Muhammad Kumail</div>
                    <div className="icons">
                        <img className='icon' src="/kumail.jpeg" alt="Kumail" />
                        <img className='icon' src='/instagram.png' alt='instagram' />
                        
                        <img className='icon' src='/whatsapp.png' alt='whatsapp' />
                        
                        <img className='icon' src='/gmail.png' alt='Founder 3' />
                    </div>
                   
          
            </div>
        </div>
    )
}

export default Founders;