import React from 'react';
import './NotAuthorized.css'; // Import the CSS file

const NotAuthorized = () => {
  return (
    <div className="not-authorized-container">
      <h1 className="not-authorized-heading">403 - Not Authorized</h1>
      <p className="not-authorized-message">
        You don't have the necessary permissions to access this page. If you believe this is an error, please contact support or return to the homepage.
      </p>
      <a href="/" className="not-authorized-button">
        Go to Homepage
      </a>
    </div>
  );
};

export default NotAuthorized;