import React, { useState } from 'react';


import Upload from './Upload';
import './NewSet.css';
import api from '../../services/api';

const NewSet = ({ existingDocuments, createSetapi, title, callBack, handleClose }) => {

    const [workflowName, setWorkflowName] = useState('');
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [loading, setLoading] = useState(false);

    const toggleDocumentSelection = (document_id) => {
        setSelectedDocuments(prevSelected => {
            if (prevSelected.includes(document_id)) {
                return prevSelected.filter(id => id !== document_id);
            } else {
                return [...prevSelected, document_id];
            }
        });
    };

    const createSet = async () => {
    
        setLoading(true);
        console.log('Selected Documents: ', selectedDocuments);
        let response = await api.post(createSetapi, {'selected_documents': selectedDocuments})
        callBack()
        handleClose()
        
    }

    return (
        <div className='flashcard-upload-container'>
            <h2>New {title}</h2>

            <div className="input-container">
                <input
                    className='input-field'
                    type="text"
                    placeholder={`Enter ${title} Set Name`}
                    value={workflowName}
                    onChange={(e) => setWorkflowName(e.target.value)} />
                <label className='input-label'></label>
            </div>

            <div className="document-list">
                {existingDocuments && existingDocuments.map((document) => (
                    <div
                        key={document.documentId}
                        className={`existing-document ${selectedDocuments.includes(document.document_id) ? 'selected' : ''}`}
                        onClick={() => toggleDocumentSelection(document.document_id)}
                    >
                        <div className="document-checkbox">
                            {selectedDocuments.includes(document.document_id    ) && (
                                <span className="checkbox-icon">✓</span>
                            )}
                        </div>
                        <div className="document-info">
                            <div className="document-title">{document.title}</div>
                         
                        </div>
                    </div>
                ))}
            </div>

            <div className={`create-btn ${loading || selectedDocuments.length < 1 ? 'loading':''}`} onClick={createSet}>Create {title} Set {loading && (<div className='loader'></div>)}</div>

        </div>
    );
};

export default NewSet;
