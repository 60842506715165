import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/modalSlice';
import './Settings.css';
import api from '../../services/api';

const Settings = () => {
    const [activeTab, setActiveTab] = useState('general');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [saving, setSaving] = useState(false);
    const [notifications, setNotifications] = useState({
        email: true,
        push: false,
        sms: false
    });
    const [privacySettings, setPrivacySettings] = useState({
        profileVisibility: 'public',
        activityStatus: true
    });
    const [theme, setTheme] = useState('light');


    useDispatch(openModal({ type: 'success', message: 'Settings saved successfully!' }));

    const handleSave = async () => {
        setSaving(true);
        const response = await api.post('/update-password', password);
        
        console.log(response);
        setSaving(false);
        setPassword('');
       
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
               
                const response = await api.get('/get-user'); // Adjust this endpoint as needed
                setName(response.data.name);
                console.log(response.data)
             
            } catch (error) {
                console.error('Failed to fetch user data:', error);
              
            } finally {
            
            }
        };

        fetchUser();
    }, []);




    return (
        <div className="settings-container">
            <h1 className="settings-title">Settings</h1>
            <div className="settings-layout">
                <div className="settings-sidebar">
                    <nav className="nav-buttons">
                        {['general', 'account'].map((tab) => (
                            <button 
                                key={tab}
                                onClick={() => setActiveTab(tab)}
                                className={`nav-button ${activeTab === tab ? 'active' : ''}`}
                            >
                                {tab.charAt(0).toUpperCase() + tab.slice(1)}
                            </button>
                        ))}
                    </nav>
                </div>
                <div className="settings-content">
                    {activeTab === 'general' && (
                        <div className="settings-section">
                            <h2 className="section-title">General Settings</h2>
                            <div className="form-group">
                                <label>Theme <span className="soon">Soon!</span></label>
                                <select
                                    value={theme}
                                    onChange={(e) => setTheme(e.target.value)}
                                    className="select-field"
                                    disabled
                                >
                                    <option value="light">Light</option>
                                    <option value="dark">Dark</option>
                                    <option value="system">System</option>
                                </select>
                            </div>
                            <button onClick={handleSave} className="button button-primary" >Save Changes</button>
                        </div>
                    )}
                    {activeTab === 'account' && (
                        <div className="settings-section">
                            <h2 className="section-title">Account Settings</h2>
                            <div className="form-group">
                                <label>Name</label>
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="input-field"
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="input-field"
                                    placeholder='*********'
                                />
                            </div>
                            <button onClick={handleSave} className="button button-primary" disabled={password==='' || saving}>Save Changes 
                                {saving && <div className="loader"></div>}</button>
                           
                        </div>
                    )}
                    {activeTab === 'notifications' && (
                        <div className="settings-section">
                            <h2 className="section-title">Notification Settings</h2>
                            {Object.entries(notifications).map(([key, value]) => (
                                <div key={key} className="switch-container">
                                    <span>{key.charAt(0).toUpperCase() + key.slice(1)} Notifications</span>
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={value}
                                            onChange={(e) => setNotifications(prev => ({ ...prev, [key]: e.target.checked }))}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                            ))}
                            <button onClick={handleSave} className="button button-primary">Save Changes</button>
                        </div>
                    )}
                    {activeTab === 'privacy' && (
                        <div className="settings-section">
                            <h2 className="section-title">Privacy Settings</h2>
                            <div className="form-group">
                                <label>Profile Visibility</label>
                                <select
                                    value={privacySettings.profileVisibility}
                                    onChange={(e) => setPrivacySettings(prev => ({ ...prev, profileVisibility: e.target.value }))}
                                    className="select-field"
                                >
                                    <option value="public">Public</option>
                                    <option value="friends">Friends Only</option>
                                    <option value="private">Private</option>
                                </select>
                            </div>
                            <div className="switch-container">
                                <span>Show Activity Status</span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        checked={privacySettings.activityStatus}
                                        onChange={(e) => setPrivacySettings(prev => ({ ...prev, activityStatus: e.target.checked }))}
                                    />
                                    <span className="slider"></span>
                                </label>
                            </div>
                            <button onClick={handleSave} className="button button-primary">Save Changes</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Settings;