import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../store/modalSlice';
import Modal from 'react-modal';
import './Modal.css'


const DynamicModal = () => {
  const { isOpen, component: ModalComponent, props } = useSelector(state => state.modal);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      overlayClassName="overlay"
      closeTimeoutMS={300}
      ariaHideApp={false}
      className={{
        base: 'modal',
        afterOpen: 'modal--after-open',
        beforeClose: 'modal--before-close'
      }}
    >
      {ModalComponent && <ModalComponent {...props} handleClose={handleClose} onClose={handleClose} />}
    </Modal>
  );
};

export default DynamicModal;