import React, { useState, useEffect } from 'react';
import './DocumentUpload.css';

import Upload from './Upload';

const DocumentUpload = ({ currentWorkflowId, newWorkflow, onUploadComplete, closeModal, handleClose }) => {

    const [params, setParams] = useState(null);

    useEffect(() => {
        let urlParams = new URLSearchParams()
        urlParams.append('workflow_id', currentWorkflowId);
        setParams(urlParams);
    }, [currentWorkflowId]);

    return (
        <div className='doc-upload-container'>
            {!newWorkflow && <h2>Upload Document</h2>}
            {newWorkflow && <h2>New Workflow</h2>}

            <div className="upload-section">
                <Upload workflowId={currentWorkflowId}
                    closeModal={closeModal}
                    onUploadComplete={onUploadComplete}
                    handleClose={handleClose}
                    >

                </Upload>
            </div>

        </div>
    );
};

export default DocumentUpload;
