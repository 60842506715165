import React, { useEffect, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import { zoomPlugin } from '@react-pdf-viewer/zoom';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import { RenderGoToPageProps } from '@react-pdf-viewer/page-navigation';

import './DocumentViewer.css';
import api from '../../services/api';

const DocumentViewer = ({ className, objectId, objectType, currentWorkflow, setTitle }) => {
  const [fileUrls, setFileUrls] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const highlightPluginInstance = highlightPlugin();
  const zoomPluginInstance = zoomPlugin();

  const [underlineStyle, setUnderlineStyle] = useState({});

  

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {(slots) => {
        const {
          CurrentPageInput,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div className="custom-toolbar">
            <div className="left-section">
              <GoToPreviousPage />
              <CurrentPageInput /> of <NumberOfPages />
              <GoToNextPage />
            </div>
            <div className="right-section">
              <ZoomOut />
              <Zoom />
              <ZoomIn />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  // const customLayoutPlugin = defaultLayoutPlugin({
  //   renderToolbar,
  //   // renderGoToPreviousPage,
  //   // renderGoToNextPage,
  // });

  const customLayoutPlugin = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs: (defaultTabs) => defaultTabs.filter((tab) => tab.title !== 'Attachment'),
  });


  useEffect(() => {
    const activeTab = document.querySelector('.tab.active');
    if (activeTab) {
      setUnderlineStyle({
        left: activeTab.offsetLeft + 'px',
        width: activeTab.offsetWidth + 'px',
      });
    }
  }, [activeTabIndex]);



  const fetchDocument = async (documentId) => {
    try {
      const response = await api.get('/document', {
        params: { document_id: documentId },
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  useEffect(() => {
    const handleFetch = async () => {
      console.log('fetching documents');
      console.log(objectType);
      if (objectType === 'document' && objectId) {
        
        const url = await fetchDocument(objectId);
        console.log(url);
        if (url) {
          setFileUrls([url]);
        }
      } else if (objectType === 'workflow' && currentWorkflow) {
        setTitle(currentWorkflow.name);
        const urls = await Promise.all(
          currentWorkflow.documents.map((doc) => fetchDocument(doc.document_id))
        );
        setFileUrls(urls.filter(Boolean));
      }
    };
    try{
      handleFetch();
    }catch(e){
      console.log(e)
    }
  }, [objectId, objectType, currentWorkflow]);

  return (
    <div className={` document-viewer ${className}`}>
      {fileUrls.length > 1 && (
        <>
          {objectType === 'workflow' && (
            <div className="tabs">
              {currentWorkflow.documents.map((doc, index) => (
                <button
                  key={doc.document_id}
                  className={`tab ${index === activeTabIndex ? 'active' : ''}`}
                  onClick={() => setActiveTabIndex(index)}
                >
                  {doc.title}
                </button>
              ))}
              <div className="tab-underline" style={underlineStyle}></div>
            </div>
          )}
          <div className="pdf-container">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
              <Viewer
                fileUrl={fileUrls[activeTabIndex]}
                plugins={[
                  // defaultLayoutPluginInstance,
                  // highlightPluginInstance,
                  // zoomPluginInstance,
                  customLayoutPlugin
                ]}
              />
            </Worker>
          </div>
        </>
      )}
       {fileUrls.length === 1 && (
        
          <div className="pdf-container">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
              <Viewer
                fileUrl={fileUrls[0]}
                plugins={[
                  // defaultLayoutPluginInstance,
                  // highlightPluginInstance,
                  // zoomPluginInstance,
                  customLayoutPlugin
                ]}
              />
            </Worker>
          </div>
       
      )}
    </div>
  );
};

export default DocumentViewer;
