import {React, useEffect} from 'react';
import './AuthLayout.css';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import axios from 'axios';
import api from '../../services/api';

const AuthLayout = ({checkAuth, triggerRefetch}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      /* global google */
      if (window.google) {
        google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleGoogleLoginSuccess,
        });

        google.accounts.id.renderButton(
          document.getElementById("google-signin-button"),
          {theme: "outline", size: "large", width: "100%"}  // customization options
        );
      } else {
        console.error("Google SDK not loaded properly.");
      }
    };

    

    // Wait for the Google SDK to be available
    const intervalId = setInterval(() => {
      if (window.google) {
        initializeGoogleSignIn();
        clearInterval(intervalId); // Clear the interval once google is defined
      }
    }, 100);

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const res = await api.post(
        `/google-login?token=${response.credential}`,
        {},
     
      );
      triggerRefetch()
      res.headers['Cache-Control'] = 'no-store, no-cache, must-revalidate, max-age=0'
      console.log(res)
      if (res.status === 200) {
        await checkAuth();
        navigate('/');
      }
    } catch (error) {
      console.error("Google login failed:", error);
    }
  };


  return (
    <div className="auth-container">
      <div className="left">
        <div className="inner-container">
          <div className="auth-title">
            <h2>Let go of the distractions. </h2>
            <h2> <span className="brand">Deductiv</span> has the rest covered.</h2>
          </div>
          <TransitionGroup component={null}>
            <CSSTransition
              key={location.key}
              classNames="fade"
              timeout={300}
            >
              <Outlet />
            </CSSTransition>
          </TransitionGroup>
          <div className='auth-divider'>
            <div className='line'></div>
            <div className='or'>or</div>
            <div className='line'></div>
          </div>
          <div className='social'>
            <div id="google-signin-button"></div>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="image">
          <img src="/login.png" alt="Login Illustration" />
        </div>
      </div>
    </div>
  );
};
export default AuthLayout;
