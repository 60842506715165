import React, { useState} from 'react';
import './NewWorkflow.css';

import Upload from './Upload';

const NewWorkflow = ({onUploadComplete, handleClose}) => {



    const [workflowName, setWorkflowName] = useState('');

 


    return (
        <div className='doc-upload-container'>
            <h2>New Workflow</h2>

            <div className="input-container">
                <input
                    className='input-field'
                    type="text"
                    placeholder="Enter Workflow Name"
                    value={workflowName}
                    onChange={(e) => setWorkflowName(e.target.value)} />
                <label className='input-label'></label>
            </div>

            <div className="upload-section">
                <Upload workflowTitle={workflowName} onUploadComplete={onUploadComplete} handleClose={handleClose}></Upload>
            </div>

        </div>
    );
};

export default NewWorkflow;
