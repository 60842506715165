import React, { useState, useEffect } from 'react';
import TopBar from './TopBar';
import DocumentViewer from './DocumentViewer';
import Chatbot from './Chatbot';
import NotesSection from './Notes';
import DocumentUpload from './DocumentUpload';
import './MainView.css';
import { CSSTransition } from 'react-transition-group';

const MainView = ({
  currentWorkflowId, 
  currentDocumentId, 
  navPaneExtended, 
  toggleNavPane, 
  checkAuth, 
  currentWorkflow,
  triggerRefetch,
}) => {
  const [notesVisible, setNotesVisible] = useState(false);
  const [workflowId, setWorkflowId] = useState(currentWorkflowId);
  const [documentId, setDocumentId] = useState(currentDocumentId);
  const [title, setTitle] = useState(null);

  const [objectType, setObjectType] = useState(null);
  const [objectId, setObjectId] = useState(null);

  useEffect(() => {
    if (currentWorkflowId) {
      setObjectId(currentWorkflowId);
      setObjectType('workflow');
    } else if (currentDocumentId) {
      setObjectId(currentDocumentId);
      setObjectType('document');
    }
  }, [currentWorkflowId, currentDocumentId]);



  return (
    <div className="main-view">
      <TopBar 
        navPaneExtended={navPaneExtended} 
        toggleNavPane={toggleNavPane} 
        checkAuth={checkAuth} 
        title={title}
        triggerRefetch={triggerRefetch}
      />
      <div className="bottom-section">
        <DocumentViewer 
          className={`document-viewer ${notesVisible ? 'flex-third' : 'flex-half'}`}
          objectId={objectId} 
          objectType={objectType} 
          currentWorkflow={currentWorkflow} 
          setTitle={setTitle}
        />
        <Chatbot 
          className={`chatbot ${notesVisible ? 'flex-third' : 'flex-half'}`} 
          notesVisible={notesVisible} 
          toggleNotes={() => setNotesVisible(!notesVisible)} 
          objectId={objectId} 
          objectType={objectType}
        />
        {/* Use CSSTransition for NotesSection */}
        <CSSTransition
          in={notesVisible}
          timeout={300}
          classNames="slide"
          unmountOnExit
        >
          <NotesSection className="notes-section flex-third"  objectId={objectId} objectType={objectType}/>
        </CSSTransition>
      </div>
    </div>
  );
};

export default MainView;
