import React from 'react';
import './NotFound.css'; // Import the CSS file

const NotFound = () => {
  return (
    <div className="not-found-container" role="alert" aria-live="assertive">
      <h1 className="not-found-heading" tabIndex="-1">
        404 - Page Not Found
      </h1>
      <p className="not-found-message">
        The page you are looking for doesn't exist or has been moved. Please check the URL or return to the homepage.
      </p>
      <a href="/" className="not-found-button">
        Go to Homepage
      </a>
    </div>
  );
};

export default NotFound;