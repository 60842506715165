import React, { useState, useEffect } from "react";
import "./Quizzes.css";
import { useDispatch } from 'react-redux';
import { openModal } from '../../store/modalSlice';
import NewSet from "./NewSet";
import api from "../../services/api";
import TopBar from './TopBar';



const Quizzes = ({navPaneExtended, toggleNavPane, checkAuth}) => {
    const [documents, setDocuments] = useState([]);
    const [quizSet, setQuizSet] = useState([]);
    const [quizzes, setQuizzes] = useState([]);


    const [selectedSetTitle, setSetTitle] = useState("");
    const [selectedSet, setSelectedSet] = useState(null);
    const [flippedCards, setFlippedCards] = useState({});
    const [animate, setAnimate] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState([]);
    const [result, setResult] = useState(null);

    const getQuizzes = async () => {
        let response = await api.get("/quizzes");
        console.log(response.data);
        setQuizSet(response.data);
    }

    useEffect(() => {
        api.get("/user-documents").then((response) => {
            console.log('documents')
            console.log(response);
            setDocuments(response.data);
        });
        getQuizzes();
    }, []);

    const openNewWorkflowModal = () => {
        dispatch(openModal({
            component: NewSet,
            props: { existingDocuments: documents, createSetapi: '/create-quizzes', title: 'Quiz', callBack: getQuizzes }
        }));
    };



    const handleSetClick = async (setId) => {
        // Dummy data for flashcards in the selected set
        let response = await api.get(`/quiz-set/${setId}`)
        console.log('quiz response')
        console.log(response.data)


        let setTitle = quizSet.find((set) => set.quiz_set_id === setId).title;
        setSetTitle(setTitle);
        setSelectedSet(setId);
        setQuizzes(response.data);
        setUserAnswers([]);
        setResult(null);
        setCurrentQuestionIndex(0);
        setAnimate(true); // Trigger the animation


    };


    const handleBackToSets = () => {
        setAnimate(false); // Reverse the animation
        setSelectedSet(null);
        setQuizzes([]);
        setResult(null);
        setUserAnswers([]);
        setCurrentQuestionIndex(0);


    };

    const handleAnswerSelection = (optionId) => {
        const updatedAnswers = [...userAnswers];
        updatedAnswers[currentQuestionIndex] = optionId; // Store the selected option's ID
        setUserAnswers(updatedAnswers);
        console.log(userAnswers);
    };  

    const handleNextQuestion = () => {
        if (currentQuestionIndex < quizzes.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const handleFinishQuiz = () => {
        const correctAnswers = quizzes.filter(
            (quiz, index) =>
                quiz.options.find(
                    (option) => option.option_id === userAnswers[index] && option.is_correct
                ) // Check if the selected option is correct
        ).length;

        setResult(correctAnswers);

    };

    return (
        <div className="qz-container">
            <TopBar navPaneExtended={navPaneExtended} toggleNavPane={toggleNavPane} checkAuth={checkAuth} title="Quizzes" newBtnClick={openNewWorkflowModal}/>
            <div className="qz-internal">
                {/* {!selectedSet && (<div className="qz-internal-header">
                 
                    <div onClick={openNewWorkflowModal} className="simple-btn-test"> Create New Quiz </div>

            
                </div>)} */}
                <div
                    className={`qz-content ${animate ? "animate" : ""} ${selectedSet ? "set-selected" : ""
                        }`}
                >
                    <div className="qz-sets">
                        {selectedSet && (
                            <div onClick={handleBackToSets} className="simple-btn">
                                &larr; Back to Sets
                            </div>
                        )}
                        <div className={`qz-sets-grid ${selectedSet ? "single" : ""}`}>
                            {quizSet.map((qz) => (
                                <div
                                    key={qz.quiz_set_id}
                                    className={`qz-card-item ${qz.quiz_set_id === selectedSet ? "active" : ""
                                        }`}
                                    onClick={() => handleSetClick(qz.quiz_set_id)}
                                >
                                    <h3>{qz.title}</h3>
                                    <img src="/bookset.jpg" alt="Set" />
                                </div>
                                
                            ))}
                        </div>
                    </div>

                    {selectedSet && result === null && (

                        <div className="qz-set">
                            <div className="qz-navigation">
                                <div onClick={handlePreviousQuestion} disabled={currentQuestionIndex === 0}
                                    className={`${currentQuestionIndex === 0 ? 'btn-disabled' : 'simple-btn'}`}>
                                    &larr; Previous
                                </div>
                                {currentQuestionIndex < quizzes.length - 1 ? (
                                    <div onClick={handleNextQuestion} className="simple-btn">Next &rarr;</div>
                                ) : (
                                    <div onClick={handleFinishQuiz} className="simple-btn"> Finish</div>
                                )}
                            </div>
                            <h2 className="qz-question">{quizzes[currentQuestionIndex].question}</h2>

                            <div className="qz-options">
                                {quizzes[currentQuestionIndex].options.map((option) => (
                                    <div
                                        key={option.option_id}
                                        className={`qz-option ${userAnswers[currentQuestionIndex] === option.option_id ? "selected" : ""}`}
                                        onClick={() => handleAnswerSelection(option.option_id)}
                                    >
                                        {option.option_text}
                                    </div>
                                ))}
                            </div>


                        </div>
                    )}
                    {selectedSet && result !== null && (
                        <div className="qz-result">
                           
                            <div className="score-heading">
                                 <h2>You got <span className="score">{result}</span> correct out of {quizzes.length} </h2>
                             </div>


                            {quizzes && quizzes.map((quiz, index) => (
                                <div key={quiz.quiz_id} className="qz-question">
                                    <h3>{quiz.question}</h3>
                                    <div className="qz-options">
                                        {quiz.options.map((option) => (
                                            <div
                                                key={option.option_id}
                                                className={`qz-option ${userAnswers[index] === option.option_id ? "selected" : ""} ${option.is_correct ? "correct" : ""} `}
                                            >
                                                {option.option_text}
                                            </div>
                                        ))}
                                    </div>
                                   
                                </div>
                            ))
                            }
                            


                            
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
};

export default Quizzes;
