// src/components/MarkdownRenderer.jsx

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './MarkdownRenderer.css'; // Import CSS for styling

const MarkdownRenderer = ({ markdownText }) => {
  return (
    <div className="markdown-container">
      <ReactMarkdown
      
        remarkPlugins={[remarkGfm]}
      >

        {markdownText}
        </ReactMarkdown>
    </div>
  );
};

export default MarkdownRenderer;
