import { createSlice } from '@reduxjs/toolkit';

const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    isOpen: false,
    component: null,
    props: {}
  },
  reducers: {
    openModal: (state, action) => {
      state.isOpen = true;
      state.component = action.payload.component;
      state.props = action.payload.props || {};
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.component = null;
      state.props = {};
    }
  }
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;