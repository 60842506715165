import React, { useState, useEffect } from "react";
import "./Feedback.css";
import api from "../../services/api";

const Feedback = ({handleClose}) => {
    const [feedback, setFeedback] = useState("");
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

     const handleSubmit = async (e) => {
        e.preventDefault();

        if (feedback === "") 
            return;

        let response = await api.post('/feedback', { feedback });
        setFeedbackSubmitted(true);
        handleClose();
    };
    

    return (
        <div className="fb-container">
            <h2>Feedback</h2>
            <form onSubmit={handleSubmit} className="fb-form">
                <textarea
                    className="fb-input"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder="Enter your feedback here..."
                />
                <button type="submit" className={`fb-btn ${feedback === ""? 'disabled': ""}`}>Submit</button>
            </form>          
        </div>
    );

};

export default Feedback;
