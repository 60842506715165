import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import api from '../services/api';

const useWorkflows = (refetchTrigger = 0) => {
    const [workflows, setWorkflows] = useState([]);
    const [expandedWorkflows, setExpandedWorkflows] = useState({});

    const fetchWorkflows = useCallback(async () => {
        try {
            const response = await api.get('/all-workflows', {
                withCredentials: true,
            });
            
            const data = response.data.map(workflow => ({
                id: workflow.workflow_id,
                name: workflow.title,
                documents: workflow.documents
            }));
            setWorkflows(data);
        } catch (error) {
            console.error('Error fetching workflows:', error);
        }
    }, []);

    useEffect(() => {
        fetchWorkflows();
    }, [fetchWorkflows, refetchTrigger]);

    const toggleWorkflow = (id) => {
        setExpandedWorkflows((prevExpanded) => ({
            ...prevExpanded,
            [id]: !prevExpanded[id],
        }));
    };

    return {
        workflows,
        expandedWorkflows,
        setWorkflows,
        toggleWorkflow,
        refetchWorkflows: fetchWorkflows,
    };
};

export default useWorkflows;