import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: `${apiUrl}`,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});


api.interceptors.response.use(
    (response) => {
        // You can modify the response data here
        return response;
    },
    error => {
        console.log('error', error)
        if (error.response) {
            switch (error.response.status) {
                case 404:

                    break;
                case 403:
                    window.location.href = '/not-authorized';
                    break;
                case 401:
                    console.log('error', error)
                    // Unauthorized - you might want to redirect to login
                    // window.location.href = '/';
                    break;
                case 422:
                    // window.location.href = '/';
                case 500:

                    break;
                default:
                    // Handle other status codes as needed
                    break;
            }
        } else if (error.request) {
            // The request was made but no response was received
            console.error('No response received:', error.request);
            // navigate('/network-error');
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error setting up request:', error.message);
        }

        // You can also dispatch actions to update your app's state here
        // e.g., dispatch(setError(error.response.data.message));

        return Promise.reject(error);
    }
);

export default api;